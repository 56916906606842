
















import { Component, Vue } from 'vue-property-decorator'
import { Slide } from './model'
import terminalModule from '@/store/terminal'
import { sortBy, uniq } from 'lodash-es'
import { settingsModule } from '@/store/settings'
import AdPlayerSlide from './AdPlayerSlide.vue'

@Component({
  components: {
    AdPlayerSlide
  }
})
export default class AdPlayer extends Vue {
  get slideList(): Slide[] {
    return sortBy(terminalModule.getters.adSlideList, ['order'])?.map(({ displayTime, img, video, id }) => ({
      id,
      duration: displayTime * 1e3,
      posterSrc: img ? settingsModule.getters.mediaPath + img : undefined,
      videoSrc: video ? settingsModule.getters.mediaPath + video : undefined
    })) ?? []
  }

  get nextSlideIndex() {
    return (this.currentSlideIndex >= this.slideList.length - 1)
      ? 0 : this.currentSlideIndex + 1
  }

  get renderSlideList(): Slide[] {
    return uniq([
      this.slideList[this.currentSlideIndex],
      this.slideList[this.nextSlideIndex]
    ])
  }

  currentSlideIndex = 0

  nextSlide() {
    this.currentSlideIndex = this.nextSlideIndex
  }

  currentTimeout: ReturnType<typeof setTimeout> | undefined

  get currentSlide() {
    return this.slideList[this.currentSlideIndex]
  }

  async startChangingSlide() {
    this.currentTimeout = setTimeout(() => {
      this.nextSlide()
      this.startChangingSlide()
    }, this.currentSlide.duration ?? 1e3)
  }

  stopChangingSlide() {
    if (this.currentTimeout !== undefined) {
      clearTimeout(this.currentTimeout)
    }
  }

  mounted() {
    this.startChangingSlide()
  }

  beforeDestroy() {
    this.stopChangingSlide()
  }
}
