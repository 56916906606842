
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { downloadingUrls } from './model'

@Component
export default class AdPlayerSlide extends Vue {
  $refs!: {
    videoRef?: HTMLVideoElement
  }

  @Prop({ type: String })
  readonly videoSrc: string | undefined

  @Prop({ type: String })
  readonly posterSrc: string | undefined

  @Prop({ type: Boolean })
  readonly playing: boolean | undefined

  mountedState = false

  @Watch('playing', { immediate: true })
  @Watch('mountedState', { immediate: true })
  @Watch('videoBlobUrl', { immediate: true })
  onChangePlaying() {
    if (this.playing && this.mountedState && this.$refs.videoRef && this.videoBlobUrl?.length) {
      this.startPlay(this.$refs.videoRef, this.videoBlobUrl)
    } else {
      this.stopPlay()
    }
  }

  statePlaying = false;

  async startPlay(videoRef: HTMLVideoElement, videoBlobUrl: string, muted = false) {
    videoRef.src = videoBlobUrl
    videoRef.muted = muted
    try {
      await videoRef.play().then(() => {
        this.statePlaying = true
      })
    } catch (error) {
      if (error instanceof DOMException && error.name === 'NotAllowedError') {
        await this.startPlay(videoRef, videoBlobUrl, true)
      } else {
        throw error
      }
    }
  }

  private videoBlobUrl: string | null = null

  async fetchFiles() {
    const videoSrc = this.videoSrc
    if (videoSrc && !downloadingUrls.has(videoSrc)) {
      downloadingUrls.add(videoSrc)
      const response = await fetch(videoSrc).finally(() => {
        downloadingUrls.delete(videoSrc)
      })
      if (response.ok) {
        this.videoBlobUrl = URL.createObjectURL(await response.blob())
      } else {
        await this.fetchFiles()
      }
    }
  }

  stopPlay() {
    if (this.statePlaying) {
      this.$refs.videoRef?.pause()
      this.statePlaying = false
    }
  }

  created() {
    this.fetchFiles()
  }

  mounted() {
    this.mountedState = true
  }

  beforeDestroy() {
    this.mountedState = false
    this.stopPlay()
  }
}
