/**
 * Рекламный слайд
 */
export type Slide = {
  id: number|string
  /**
   * Ссылка на видео файл
   */
  videoSrc?: string
  /**
   * Ссылка на файл изображения
   */
  posterSrc?: string
  /**
   * Время отображения в ms
   */
  duration: number
}

/**
 * Url которые в процессе скачивания
 */
export const downloadingUrls = new Set<string>()
