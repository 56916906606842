



































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { RouteNames } from '@/router'
import terminalModule from '@/store/terminal'
import { Flicking, FlickingOptions } from '@egjs/vue-flicking'
import { AutoPlay } from '@egjs/flicking-plugins'
import { settingsModule } from '@/store/settings'
import { AdPlayer } from '@/components/adPlayer'

@Component({
  components: {
    Flicking,
    AdPlayer
  }
})
export default class Index extends Vue {
  readonly RouterNames = RouteNames
  banner : string | null = '/img/banner.jpg';
  get logo() {
    return terminalModule.getters.logo
  }

  get banners() {
    return terminalModule.getters.banners?.filter(banner => !!banner)
  }

  get terminalInfo() {
    return terminalModule.getters.terminal
  }

  get isDemoMode() {
    return terminalModule.getters.terminal?.isDemoMode
  }

  get hasAbSlides(): boolean {
    return !!terminalModule.getters.adSlideList?.length
  }

  isShowPlayer = false

  @Watch('isDemoMode')
  onIsDemoModeChanged(val: string) {
    if (val) {
      this.$router.push({ name: RouteNames.catalog })
    }
  }

  flickingOptions:Partial<FlickingOptions> = {
    circular: true,
    horizontal: false,
    align: 'center',
    adaptive: true
  }

  flickingPlugins = [
    new AutoPlay({
      duration: settingsModule.state.publicSettings.bannerDuration
    })
  ]

  onCLickAdPlayer() {
    this.$router.push({
      name: RouteNames.catalog
    })
  }

  mounted() {
    setTimeout(() => {
      this.isShowPlayer = true
    }, settingsModule.state.publicSettings.adStartTime)
  }
}
